import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import { ContactMail } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';


const ContinuousSlider = ({selectedDistance, setSelectedDistance, selectedFilter}) => {
  
    const handleChange = (event, newValue) => {
        setSelectedDistance(newValue);
    };
    return (      
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <Slider min={5} disabled={selectedFilter !== 'near_me'} aria-label="Volume" value={selectedDistance} onChange={handleChange} />
        </Stack>
    );
  }

const RadioButtonGroup = ({selectedDistance, selectedFilter, setSelectedFilter, userLocation, setUserLocation, setLocationLoad}) => {
    const handleChange = (event) => {
        setSelectedFilter(event.target.value);
        if (event.target.value === 'near_me' && !userLocation) {
            if (navigator.geolocation) {
                setLocationLoad(true)
                navigator.geolocation.getCurrentPosition(
                  position => {
                    setUserLocation([position.coords.longitude, position.coords.latitude])
                    setLocationLoad(false)
                  },
                  error => {
                    console.log(error.message);
                    setLocationLoad(false)
                  }
                );
              } else {
                console.log('Geolocation is not supported by this browser.');
              }
        }
      };
    return (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={selectedFilter}
          onChange={handleChange}
        >
          <FormControlLabel  value="all" control={<Radio />} label="All" />
          <FormControlLabel value="near_me" control={<Radio />} label={`${selectedDistance} kms near me`} />
        </RadioGroup>
      </FormControl>
    );
  }
export default function AlertDialog({showFilters, setShowFilters, selectedFilter, setSelectedFilter, selectedDistance, setSelectedDistance, userLocation, setUserLocation, applyFilter, locationLoad, setLocationLoad}) {

  const handleOkay = () => {
    setShowFilters(false);
  };

  return (
    <div>
      <Dialog
        open={showFilters}
        fullWidth
        onClose={handleOkay}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Select Filter
        </DialogTitle>
        <DialogContent >
            <RadioButtonGroup locationLoad={locationLoad} setLocationLoad={setLocationLoad} userLocation={userLocation} setUserLocation={setUserLocation} selectedDistance={selectedDistance} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}/>
            <ContinuousSlider selectedFilter={selectedFilter} selectedDistance={selectedDistance} setSelectedDistance={setSelectedDistance}/>
            {selectedFilter === 'near_me' && locationLoad && (<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center"><Typography variant="caption">Getting your location</Typography><CircularProgress size={20}/></Stack>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleOkay}>
            Cancel
          </Button>
          <Button disabled={selectedFilter==='near_me' && !userLocation} onClick={applyFilter} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
