import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LandingPage from './Landing.js'
function App() {
  return (
    <Router>
        <Routes>
           <Route exact path="/" element={<LandingPage/>}/> 
           <Route exact path="/restaurant/:id" element={<LandingPage/>}/> 
        </Routes>
    </Router>
  )
}

export default App;
