import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FeedbackIcon from '@mui/icons-material/Feedback';

export default function SwipeableTemporaryDrawer({open, setOpen, setInfoOpen}) {
  const toggleDrawer =
    (_open) =>
    (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return;
      }
      setOpen( _open );
    };


  return (
    <div>
        <React.Fragment>
          <SwipeableDrawer
            anchor={'left'}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
              <Box
                role="presentation"
                sx={{width:250}}
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{padding:5}}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: { sm: 'block' } }}
                        >
                            Map Ma Food
                        </Typography>
                    </Box>
                <List>
                <Divider />
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setInfoOpen(true)}}>
                        <ListItemIcon>
                            <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                        </ListItemButton>
                    </ListItem>
                    
                </List>
                <Divider />
                </Box>
          </SwipeableDrawer>
        </React.Fragment>
    </div>
  );
}