import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Vector";
import OSM from 'ol/source/OSM';
import Feature from "ol/Feature";
import Point from 'ol/geom/Point'
import Overlay from 'ol/Overlay';
import {Style, Icon, Text, Stroke, Fill} from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { useEffect, useRef, useState } from 'react';
import Detail from './detail.js'
import { useNavigate } from 'react-router-dom';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useTheme } from "@mui/material/styles";
function MyMap({markers, currentView, userLocation, setUserLocation, selectedFilter, selectedRestaurant, setSelectedRestaurant, isDetailOpen, setIsDetailOpen}) {
  const mapRef = useRef(null);
  const [map, setMap] = useState();

  const [zoom, setZoom] = useState(10)
  const [center, setCenter] = useState(fromLonLat([76.30132077867154, 9.981061820610766]));
  const navigate = useNavigate();

  useEffect(() => {
    const viewOptions = {
      center: center,
      zoom: 10,
    }
    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
          name:'tile'
        }),
      ],
      view: new View(viewOptions),
    });
    setMap(initialMap);
    initialMap.getView().on('change:resolution', (event) => {
        const zoom = initialMap.getView().getZoom();
        setZoom(zoom);
    });
  },[currentView]) 
  useEffect(() => {
    if (userLocation) {
      map.getView().setCenter(fromLonLat(userLocation));
    }

  }, [markers])

    useEffect(() => {
    if (userLocation && map) {
      map.getView().setCenter(fromLonLat(userLocation));
    }

  }, [userLocation])

useEffect(()=> {
  if (map) {
    map.getLayers().getArray().filter(layer=>layer.get('name')==='marker').forEach(layer => map.removeLayer(layer))
  let markerFeatures =markers.map((marker) => {
    if (marker.latLong) {
      const [lon, lat] = marker.latLong.split(','); 
    const markerFeature = new Feature({
      geometry: new Point(fromLonLat([lat, lon])),
      name: marker.name,
      id:marker._id,
      clickable: true
    });
    let iconStyle;
    //if (zoom >= 10) {
      iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 32],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          src: "./marker.png",
          scale: 0.15
        }),
        text: new Text({
          text: marker.name,
          font: '12px Arial, sans-serif',
          offsetY: -20,
          fill: new Fill({
            color: 'red',
          }),
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
        }),
      });
  /*} else {
    console.log(`zoom is less than  ${zoom}`)
    iconStyle = new Style({
      image: new Icon({
        anchor: [0.5, 32],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: "./marker.png",
        scale: 0.08
      })
    });
  }*/
    markerFeature.setStyle(iconStyle);
    return markerFeature;
  }
  });
  markerFeatures = markerFeatures.filter(mfeature=> mfeature !== undefined)
  const markerSource = new VectorSource({
    features: markerFeatures,
  });

  const markerLayer = new VectorLayer({
    source: markerSource,
    name:'marker'
  });

    map.addLayer(markerLayer);
  map.on("click", function(e) {
    map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        const _rest = markers.find(x=>x._id === feature.values_.id);
        if (_rest) {
            navigate(`/restaurant/${_rest._id}`);
          //setSelectedRestaurant(_rest);
          //setIsDetailOpen(true)
        }
    })
});

  
  }
}, [markers, map])
    const recenter = () => {
        if (!userLocation) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                    setUserLocation([position.coords.longitude, position.coords.latitude])
                },
                    error => {
                        console.log(error.message);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
            }
        }
        if (userLocation) {

          map.getView().setCenter(fromLonLat(userLocation));
        }
    }
    const theme = useTheme();
  if (currentView === 'mapview') {
    return (
        <div style={{height:'calc(100vh - 60px)', width:'100%', position:'relative', overflowY:'scroll'}}>
            <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
            <div style={{width:50, backgroundColor:theme.palette.primary.main, height:50, borderRadius:25, position:'absolute', bottom:150, right:50, zInde:3, display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer',}}><MyLocationIcon onClick={()=>{recenter()}} sx={{color:'white'}}/></div>
            <Detail open={isDetailOpen} selectedRestaurant={selectedRestaurant} setOpen={setIsDetailOpen} setSelectedRestaurant={setSelectedRestaurant} />
        </div>
    );
  }
  return null;
}

export default MyMap;
