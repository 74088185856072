import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function AlertDialog({open, setOpen}) {

  const handleOkay = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOkay}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Info
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            For suggestions, complaints, feature requests and pretty much anything,  mail to contact@profile.akbarali.co.in
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkay} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}