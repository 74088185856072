import { useEffect, useState, Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Detail from './detail.js'
function ItemList({ markers, currentView }) {
    const [selectedRestaurant, setSelectedRestaurant] = useState(undefined);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    if (currentView==='listview') {
        return (
            <div style={{height:'calc(100vh - 60px)', width:'100%', overflowY:'scroll'}}>
                <List component="nav" style={{ overflowY:'scroll', overflowX:'hidden',}}>
                    {markers.map(item => (
                        <Fragment key={item._id}>
                            <ListItem button onClick={()=>{
                                const _rest = markers.find(x=>x._id === item._id)
                                    if (_rest) {
                                        setSelectedRestaurant(_rest)
                                        setIsDetailOpen(true)
                                    }
                                }}>
                                <ListItemAvatar>
                                    <Avatar alt={item.name} src={item.coverphoto || '/default-corporate-image.png'} />
                                </ListItemAvatar>
                                <ListItemText primary={item.name} secondary={
                                    <Fragment>
                                    <Typography
                                        sx={{ display: 'block' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {item.place}
                                    </Typography>
                                    <Typography
                                        sx={{ display: 'block' }}
                                        component="span"
                                        variant="subtitle2"
                                        color="text.primary"
                                    >
                                        {item.landmark}
                                    </Typography>
                                    
                                    </Fragment>
                                }
                                />
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </Fragment>
                    ))}
                </List>
                <Detail open={isDetailOpen} selectedRestaurant={selectedRestaurant} setOpen={setIsDetailOpen} setSelectedRestaurant={setSelectedRestaurant} />
            </div>
        );
    }
    return null;
}

export default ItemList;