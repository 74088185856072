
import { useEffect, useRef, useState } from 'react';
import MapView from './components/mapView.js'
import AppBar from './components/appbar.js'
import ListView from './components/listView.js'
import InfoPopUp from './components/infopopup.js'
import FilterPopUp from './components/filterpopup.js'
import {Helmet} from "react-helmet";
import { useParams } from 'react-router-dom';
function MyMap(props) {
    const { id } = useParams();
  const [markers, setMarkers] = useState([]);
  const [currentView, setCurrentView] = useState('mapview')
  const [showInfo, setShowInfo] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [showFilters, setShowFilters] = useState(false)
  const [selectedDistance, setSelectedDistance] = useState(10)
  const [userLocation, setUserLocation] = useState(undefined)
  const [locationLoad, setLocationLoad] = useState(false)
  const [load, setLoad] = useState(false)
  const [selectedRestaurant, setSelectedRestaurant] = useState(id ? id : undefined);
  const [isDetailOpen, setIsDetailOpen] = useState(id ? true : false);
  useEffect(() => {
      console.log(id, markers.length)
    const fetchMarkers = async () => {
      const response = await fetch('/api/list');
      const data = await response.json();      
      setMarkers(data.result);
    };
    if (!id && markers.length < 1) {
        fetchMarkers();
    }
  }, [id]);
  useEffect(()=> {
      if (id) {
        setSelectedRestaurant(id);
        setIsDetailOpen(true)
      } else {
       setSelectedRestaurant(undefined);
        setIsDetailOpen(false)

      }
  }, [id])
  const applyFilter = async() => {
    setLoad(true)
    try {
      let url='/api/list';
      if (selectedFilter === 'near_me' && userLocation) {
        url += `?fDistance=${selectedDistance}&lat=${userLocation[1]}&long=${userLocation[0]}`
      }
      const response = await fetch(url);
      const data = await response.json();      
      setMarkers(data.result);
      setLoad(false)
    } catch (e) {
      setLoad(false)
    }
    setShowFilters(false);
  }
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Map Ma Food</title>
          <link rel="icon" href="./shallow-pan-of-food.svg" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <meta
            name="description"
            content="An application listing local restaurants"
          />
          <meta
            name="description"
            content="An application listing local restaurants"
          />
          <meta property="og:title" content="Map Ma Food" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://mapmafood.akbarali.co.in" />
          <meta property="og:image" content="./shallow-pan-of-food.svg" />
          <link rel="manifest" href="./manifest.json" />
      </Helmet>
      <AppBar  setShowFilters={setShowFilters} currentView={currentView} setOpen={setShowInfo} setCurrentView={setCurrentView}/>
      <MapView
      userLocation={userLocation}
      setUserLocation={setUserLocation}
      selectedFilter={selectedFilter}
      markers={markers} 
      currentView={currentView}
      selectedRestaurant={selectedRestaurant}
      setSelectedRestaurant={setSelectedRestaurant}
      isDetailOpen={isDetailOpen}
      setIsDetailOpen={setIsDetailOpen}
      />
      <ListView selectedFilter={selectedFilter} markers={markers} currentView={currentView}/>
      <InfoPopUp open={showInfo} setOpen={setShowInfo}/>
      <FilterPopUp 
        userLocation={userLocation} 
        setUserLocation={setUserLocation} 
        selectedDistance={selectedDistance} 
        setSelectedDistance={setSelectedDistance} 
        showFilters={showFilters} 
        setShowFilters={setShowFilters} 
        selectedFilter={selectedFilter} 
        setSelectedFilter={setSelectedFilter}
        applyFilter={applyFilter}
        locationLoad={locationLoad}
        setLocationLoad={setLocationLoad}
      />
    </div>
  )
}
export default MyMap;
