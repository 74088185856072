import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import DialogeBox from './dialogebox.js';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
const gotoLoc = (latLong) =>{
    window.open(`https://www.google.com/maps/search/?api=1&query=${latLong}`);
}

function Popup(props) {
  const { open, setSelectedRestaurant, setOpen, selectedRestaurant} = props;



  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [details, setDetails] = useState(undefined)
  const dialogTitle = "We do not know the exact location";
  const dialogMessage = "We could not find the exact location. so this will take you to an approximate location, mostly to the landmark mentioned."
    const getDetail = async(_id) => {
        if (_id) {
        try {
            const _detail = await fetch(`/api/list/${_id}`);
            const _data = await _detail.json();
            setDetails(_data)
        } catch (e) {
            console.log(e)
        }
        }
    }
  const handleDirectionClick = () => {
      if (isExact) {
          gotoLoc(latLong)  
      } else {
        setDialogOpen(true)
      }
  }
  const share = async () => {
    try {
      await navigator.share({
        title: 'Map  Ma Food',
        text: props.name,
        url: `https://mapmafood.akbarali.co.in/restaurant/${selectedRestaurant}`,
      });
      console.log('Successfully shared');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

    const theme = useTheme();
useEffect(()=> {
    getDetail(selectedRestaurant)
}, [selectedRestaurant])

const {coverPhoto='/default-corporate-image.png', _id, name, place, isExact, latLong, landmark, district, workingHours, featuredItem}=details || {};

  if(open) {
    return (
        <Paper sx={{width:{md:'50%', zIndex:5, sm: '100%', position:'absolute', top:0, right:0, height:'100%', width:'100%', overflowY:'scroll'}}}>
        <div style={{position:'relative'}}>
            <CloseIcon onClick={()=>{console.log('click'); navigate('/')}} sx={{position:'absolute', top:10, zIndex:6, right:10, cursor:'pointer', stroke: "#ffffff", strokeWidth: 0.5}}/>
            <img src={coverPhoto} alt="Cover" style={{width: '100%',height: 400, objectFit: 'cover'}} />
            <div style={{position:'absolute', height:50, width:50, zIndex:7, borderRadius:25, bottom:10, display:'flex', alignItems:'center', justifyContent:'center', right:10, cursor:'pointer', backgroundColor:theme.palette.primary.main }}>
                <ShareIcon onClick={()=>{share()}} sx={{color:'white'}}/>
            </div>
        </div>
        <div style={{padding:'2px 20px'}} >
            <div style={{display:'flex', alignItems:'center', position: 'relative', marginTop:'10px'}}>
                <Typography variant="h4" sx={{marginTop:2, marginRight:'10px', marginTop:0}}>
                    {name}
                </Typography>
                <Chip sx={{textTransform: 'capitalize'}} label={district} variant="outlined" />

            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Typography variant="h8" sx={{marginTop:2, fontWeight:'bold', marginRight:2}}>Place : </Typography>
                <Typography variant="h8" sx={{marginTop:2, marginRight:5}}>
                    {place}
                </Typography>
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Typography variant="h8" sx={{marginTop:2, fontWeight:'bold', marginRight:2}}>Landmark : </Typography>
                <Typography variant="h8" sx={{marginTop:2}}>
                    {landmark}
                </Typography>
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Typography variant="h8" sx={{marginTop:2, fontWeight:'bold', marginRight:2}}>Working Hours : </Typography>
                <Typography variant="h8" sx={{marginTop:2}}>{workingHours}</Typography>
            </div>
            <div style={{display:'flex', alignItems:'center'}}>
                <Typography variant="h8" sx={{marginTop:2, fontWeight:'bold', marginRight:2}}>Featured Dishes : </Typography>
                <Typography variant="body1" sx={{marginTop:2}} >
                    {featuredItem}
                </Typography>
            </div>
            <Button variant="contained" color="primary" onClick={()=>{}} sx={{marginTop:2}} disabled={!latLong} onClick={handleDirectionClick}>
                    Directions
            </Button>
        </div>
        <DialogeBox open={dialogOpen} setOpen={setDialogOpen} title={dialogTitle} message={dialogMessage} handleOkay={()=>gotoLoc(latLong)}/>
        </Paper>
    );
  }
  return null
}


export default Popup;
